<template>
    <div :class="['teaser-card d-flex flex-col', { 'teaser-card--hover': hover }]">
        <smart-link
            v-if="hasButton"
            class="teaser-card__img"
            :url="buttonUrl"
            :target="props.model.cta.target"
            :gtm-label="props.model.cta.gtmLabel || props.model.cta.title"
            :gtm-action="defaultGtmAction"
            :aria-label="props.model.cta.title"
            tabindex="-1"
            @mouseover="hover = true"
            @mouseleave="hover = false"
        >
            <smart-image
                v-if="props.model.image && props.model.image.url"
                class="teaser-card__img__comp"
                :url="props.model.image.url"
                :alt="props.model.image?.properties?.altText"
                :height="props.model.image?.properties?.height"
                :width="props.model.image?.properties?.width"
                :focal-point="props.model.image?.properties?.imageFocalPoint"
                type="bg"
            />
        </smart-link>
        <div v-else class="teaser-card__img">
            <smart-image
                v-if="props.model.image && props.model.image.url"
                class="teaser-card__img__comp"
                :url="props.model.image.url"
                :alt="props.model.image?.properties?.altText"
                :height="props.model.image?.properties?.height"
                :width="props.model.image?.properties?.width"
                :focal-point="props.model.image?.properties?.imageFocalPoint"
                type="bg"
            />
        </div>
        <div
            v-if="props.model.textArea"
            :class="['teaser-card__body', { 'teaser-card__body--no-cta': !hasButton }]"
            v-html="props.model.textArea"
        />
        <div v-if="hasButton" class="teaser-card__cta flex-center">
            <smart-link
                :class="buttonClass"
                :url="buttonUrl"
                :target="props.model.cta.target"
                :gtm-label="props.model.cta.gtmLabel"
                :gtm-action="defaultGtmAction"
                @mouseover="hover = true"
                @mouseleave="hover = false"
                v-text="props.model.cta.title"
            />
        </div>
    </div>
</template>

<script setup lang="ts">
import { ref, computed } from 'vue'
import { TeaserCardBlockModel } from '@/types'

const props = defineProps<{
    model: TeaserCardBlockModel
}>()

const hover = ref(false)

const hasButton = computed(() => {
    return props.model?.cta && props.model?.cta.link && props.model?.cta.title
})

const buttonUrl = computed(() => {
    return props.model?.cta && props.model?.cta.link && props.model?.cta.link.url ? props.model.cta.link.url : '#'
})
const buttonClass = computed(() => {
    return props.model?.ctaStyle !== 'default' ? props.model?.ctaStyle : 'btn-solid-md-secondary'
})
const defaultGtmAction = computed(() => {
    return props.model?.cta && props.model?.cta.gtmAction ? props.model?.cta.gtmAction : 'teaser card'
})
</script>

<style lang="scss">
.teaser-card {
    $hover: '';
    position: relative;
    height: 100%;
    background-color: $color-grey-200;
    display: flex;
    padding-bottom: $spacer-xl;
    overflow: hidden;
    transition: background-color $transition-dur $transition-ease-out-quad;

    &--hover {
        $hover: &;
        background-color: lighten($color-grey-200, 5%);
    }

    &__img {
        transition: transform $transition-dur $transition-ease-out-quad;
        outline: 0;

        &__comp {
            padding-bottom: 62.5%;
        }

        #{$hover} & {
            transform: scale(1.05) translate3d(0, 0, 0);
        }
    }

    &__body {
        position: relative;
        z-index: z(element, default);
        background-color: $color-white;
        width: calc(100% - #{$spacer-xl * 2});
        margin: -$spacer-xl auto 0;
        padding: $spacer-lg $spacer-lg calc(#{$spacer-lg} - 4px);
        text-align: center;
        flex: 1;

        &--no-cta {
            padding: $spacer-lg;
        }

        h1,
        h2,
        h3 {
            margin-top: 0;
        }

        p:last-of-type {
            margin-bottom: 0;
        }
    }

    &__cta {
        margin-top: auto;
        background-color: $color-white;
        width: calc(100% - #{$spacer-xl * 2});
        margin: 0 auto;
        padding: 4px $spacer-lg $spacer-lg;
    }
}
</style>
