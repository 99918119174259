<template>
    <footer class="footer text-light mt-3">
        <div class="container">
            <div v-if="props.model.linkColumns && props.model.linkColumns.length" class="grid">
                <div :class="[spanClass, 'footer__column']">
                    <smart-svg
                        v-if="props.model.logoImage?.url"
                        class="footer__logo mb-2"
                        :url="props.model.logoImage.url"
                        aria-label="Alm. Brand"
                    />

                    <div class="footer__column__companyinfo">
                        <template v-if="props.model.companyName"> {{ props.model.companyName }}<br /> </template>
                        <template v-if="props.model.companyAddress"> {{ props.model.companyAddress }}<br /> </template>
                        <template v-if="props.model.companyCity">
                            {{ props.model.companyCity }}
                        </template>
                    </div>

                    <div class="footer__column__social mt-1">
                        <smart-link
                            v-if="props.model.socialLinkedIn"
                            :url="props.model.socialLinkedIn"
                            target="_blank"
                            rel="noopener"
                            :use-vue-route="false"
                            aria-label="Alm. Brand Group LinkedIn"
                            gtm-label="LinkedIn"
                            :gtm-action="gtmActionCategory"
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
                                <path d="M0,0H32V31.816H0Z" transform="translate(0 0.184)" fill="none" />
                                <rect width="32" height="32" fill="none" />
                                <g transform="translate(2.877 3.045)">
                                    <path
                                        d="M-122.678,233.076h-22.383A1.912,1.912,0,0,0-147,234.958v22.348a1.913,1.913,0,0,0,1.939,1.885h22.383a1.917,1.917,0,0,0,1.944-1.885V234.958A1.915,1.915,
                    0,0,0-122.678,233.076Zm-16.531,22.253h-3.9V242.866h3.9Zm-1.947-14.166a2.252,2.252,0,0,1-2.259-2.246,2.252,2.252,0,0,1,2.259-2.245,2.253,2.253,0,0,1,2.258,
                    2.245A2.253,2.253,0,0,1-141.157,241.163Zm16.539,14.166h-3.893v-6.061c0-1.445-.027-3.3-2.025-3.3-2.027,0-2.338,1.574-2.338,3.2v6.166h-3.892V242.866h3.737v1.7h.052a4.1,
                    4.1,0,0,1,3.686-2.013c3.945,0,4.673,2.582,4.673,5.937Z"
                                        transform="translate(147 -233.076)"
                                        fill="#fff"
                                    />
                                </g>
                            </svg>
                        </smart-link>
                    </div>
                </div>

                <div
                    v-for="(column, index) in props.model.linkColumns"
                    :key="index"
                    :class="[spanClass, 'footer__column']"
                >
                    <h3 v-if="column.header" class="footer__column__heading mt-0" v-html="column.header" />
                    <ul v-if="column.links.length" class="footer__column__links">
                        <li v-for="(link, idx) in column.links" :key="idx" class="footer__column__links__item">
                            <smart-link
                                class="footer__column__links__item__link"
                                :url="link.url"
                                :target="link.target"
                                :title="link.title"
                                :is-external-link="link.isExternalLink"
                                :use-vue-route="link.useVueRoute"
                                :gtm-action="gtmActionCategory"
                            >
                                {{ link.text }}
                            </smart-link>
                        </li>
                    </ul>
                </div>
            </div>
        </div>

        <div
            v-if="props.model.policyLinks && props.model.policyLinks.length"
            class="footer__terms text-light container"
        >
            <div class="grid">
                <div class="span12">
                    <smart-link
                        v-for="(link, idx) in props.model.policyLinks"
                        :key="idx"
                        :url="link.url"
                        :title="link.title"
                        :target="link.target"
                        :is-external-link="link.isExternalLink"
                        :use-vue-route="link.useVueRoute"
                        :gtm-action="gtmActionCategory"
                    >
                        {{ link.text }}
                    </smart-link>
                </div>
            </div>
        </div>
    </footer>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { FooterBlockModel } from '@/types'

const props = defineProps<{
    model: FooterBlockModel
}>()

const gtmActionCategory = 'footer navigation'

const spanClass = computed(() => {
    if (props.model?.linkColumns && props.model?.linkColumns.length) {
        const spanNumber = 12 / (props.model?.linkColumns.length + 1)
        return `span${spanNumber}`
    }

    return 'span3'
})
</script>

<style src="./footerBlock.scss" lang="scss"></style>
