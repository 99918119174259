/**
 * Type describing configuration to using harmonia
 */
export type CmsConfig = {
    /**
     * settings for CmsBlockComponentSelector and CmsPageComponentSelector components
     */
    componentSelector: {
        /**
         * used when previewing an block in the editor.
         * the component must be registed globally in vue.
         * @default "BlockPreview"
         */
        blockPreviewComponent: string

        /**
         * used when an block type cannot be matched to a component
         * the component must be registed globally in vue.
         * @default "GenericBlock"
         */
        fallbackBlockComponent: string
    }

    /**
     * settings for the CmsContentArea component
     */
    contentArea: {
        /**
         * the default css class for the root/outer html tag
         * @default "contentArea"
         */
        cssClass: string

        /**
         * the default css class(es) for the item html tag
         * @default "contentAreaItem"
         */
        cssItemClass: string

        /**
         * the default root html tag
         * if empty then the content area items will be rendered without a enclosing root element
         * @default "div"
         */
        rootHtmlTag: string

        /**
         * the default item html tag
         * @default "div"
         */
        itemHtmlTag: string
    }

    /**
     * settings for the CmsLink component
     */
    link: {
        /**
         * the default css class for links
         * @default ""
         */
        cssClass: string
    }

    /**
     * settings for the cms stores
     */
    store: {
        /**
         * Settings for the cmsContext store
         */
        cmsContext: {
            mutations: {
                /**
                 * the id for the UPDATE_CONTENT mutation
                 * @default "cmsContext/UPDATE_CONTEXT"
                 */
                UPDATE_CONTEXT: string
            }
        }
        /**
         * Settings for the cmsDataModel store
         */
        cmsDataModel: {
            mutations: {
                /**
                 * the id for the UPDATE_MODEL mutation
                 * @default "cmsDataModel/UPDATE_MODEL"
                 */
                UPDATE_MODEL: string
            }
            actions: {
                /**
                 * the id for the UPDATE_MODEL_BY_URL action
                 * @default "cmsDataModel/UPDATE_MODEL_BY_URL"
                 */
                UPDATE_MODEL_BY_URL: string
            }
        }
    }
}

export const defaultCmsConfig: CmsConfig = {
    componentSelector: {
        blockPreviewComponent: 'BlockPreview',
        fallbackBlockComponent: 'GenericBlock',
    },
    contentArea: {
        cssClass: 'contentArea',
        cssItemClass: 'contentAreaItem',
        rootHtmlTag: 'div',
        itemHtmlTag: 'div',
    },
    link: {
        cssClass: '',
    },
    store: {
        cmsContext: {
            mutations: {
                UPDATE_CONTEXT: 'cmsContext/UPDATE_CONTEXT',
            },
        },
        cmsDataModel: {
            actions: {
                UPDATE_MODEL_BY_URL: 'cmsDataModel/UPDATE_MODEL_BY_URL',
            },
            mutations: {
                UPDATE_MODEL: 'cmsDataModel/UPDATE_MODEL',
            },
        },
    },
}
