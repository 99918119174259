<template>
    <div class="iFrame-container">
        <iframe
            ref="iframe"
            :src="props.model.iframeUrl"
            :title="props.model.title"
            :width="props.model.width"
            scrolling="no"
            frameborder="0"
            onload="iFrameResize()"
        />
    </div>
</template>

<script setup lang="ts">
import { onMounted, ref } from 'vue'
import { IFrameBlockModel } from '@/types'

const props = defineProps<{
    model: IFrameBlockModel
}>()

const iframe = ref<HTMLIFrameElement | null>(null)

onMounted(() => {
    window.addEventListener(
        'message',
        function (e) {
            const message = e.data
            if (iframe.value && message.height && message.width) {
                iframe.value.style.height = message.height + 'px'
                iframe.value.style.width = message.width + 'px'
            }
        },
        false
    )
})
</script>

<style lang="scss" scoped>
.iFrame-container {
    display: flex;
    justify-content: center;
}
</style>
