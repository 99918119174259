/* eslint-disable */
/*
  USAGE:
  1. <element v-inview="{ offset: `${offset}px` }" /> // offset intersection observer trigger, use px or % of element
  2. <element v-inview @intersecting="someFunction" /> // use "intersecting" event to fire method when element in viewport
  3. <element v-inview="{ animate: true, animation: 'fade', offset: '-200px' }"> // add custom animation to element when enter viewport
    - "animate"-class will be added to element, animation-class will be added to element when entering viewport, "fade" in this ex.
*/

const initIntersectionObserver = (el: any, binding: any, value: any) => {
    if (/\S+/.test(value)) {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (binding.value && binding.value.animate) el.classList.add('animate')

                    if (entry && entry.isIntersecting) {
                        if (binding.value && binding.value.animate) el.classList.add(binding.value.animation)

                        const event = new CustomEvent('intersecting')
                        el.dispatchEvent(event)

                        observer.disconnect()
                    }
                })
            },
            {
                rootMargin: binding.value && binding.value.offset ? `0px 0px ${binding.value.offset} 0px` : '0px',
            }
        )
        observer.observe(el)
        el.$inview = observer
    }
}

export default function (Vue: any) {
    Vue.directive('inview', {
        mounted(el: any, binding: any, { value }: any) {
            initIntersectionObserver(el, binding, { value })
        },
        updated(el: any, binding: any, { value }: any) {
            if (el.$inview) el.$inview.unobserve(el)
            initIntersectionObserver(el, binding, { value })
        },
        beforeUnmount(el: any) {
            if (el.$inview) el.$inview.unobserve(el)
        },
    })
}
