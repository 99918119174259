<template>
    <header
        ref="header"
        :class="[
            'header',
            'container',
            textColorClass,
            {
                'header--mobile': isMobileHeader,
                'header--frontpage': isFrontPage,
                'header--shrink': shrinkHeader || invertFrontPageHeader,
            },
        ]"
    >
        <div class="grid">
            <div ref="containerWidth" class="span12 flex-v-center text-menu">
                <div v-if="props.model.logoImage && props.model.logoImage.url" class="header__logo">
                    <smart-link :url="homeUrl" gtm-label="header logo" :gtm-action="gtmActionCategory">
                        <smart-svg :url="props.model.logoImage.url" aria-label="Alm. Brand Group" />
                    </smart-link>
                </div>
                <nav v-if="props.model.headerLinks.length" :class="[{ active: isMenuActive }]">
                    <menu-component
                        :header-links="props.model.headerLinks"
                        :is-mobile-menu="isMobileHeader"
                        :text-color="textColorClass"
                        :gtm-action-cat="gtmActionCategory"
                    />

                    <language-picker
                        v-if="isMobileHeader"
                        :class="{ 'lang-picker--mobile': isMobileHeader }"
                        :gtm-action-cat="gtmActionCategory"
                    />
                </nav>

                <div v-if="!isMobileHeader" class="header__actions">
                    <language-picker :text-color="textColorClass" :gtm-action-cat="gtmActionCategory" />
                </div>

                <div
                    v-if="isMobileHeader"
                    class="header__toggle flex-v-center"
                    @click="store.commit('toggleMenu', !isMenuActive)"
                >
                    <hamburger
                        :menu-btn-label="menuBtnLabel"
                        :is-menu-active="isMenuActive"
                        :gtm-action-cat="gtmActionCategory"
                    />
                </div>
            </div>
        </div>
        <teleport to="#app">
            <transition name="fade">
                <div v-if="isMobileHeader && isMenuActive" class="header__overlay" @click="handleOverlayClick()" />
            </transition>
        </teleport>
    </header>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { useStore } from 'vuex'
import { HeaderBlockModel } from '@/types'
import Hamburger from '@/components/Hamburger/Hamburger.vue'
import MenuComponent from '@/components/Menu/Menu.vue'
import LanguagePicker from '@/components/LanguagePicker/LanguagePicker.vue'

const store = useStore()
const props = defineProps<{
    model: HeaderBlockModel
}>()

const gtmActionCategory = 'header navigation'

const menuBtnLabel = computed(() => {
    return props.model?.menuMobilLabelText || ''
})
const isMobileHeader = computed(() => {
    return store.state.windowWidth < 920
})
const isMenuActive = computed(() => {
    return store.state.isMenuOpen
})
const isFrontPage = computed(() => {
    return store.state.cmsDataModel.model.contentType === 'FrontPage'
})
const shrinkHeader = computed(() => {
    return store.state.scrollPosition > 20
})
const invertFrontPageHeader = computed(() => {
    return store.state.scrollPosition < 20 && isFrontPage.value && isMenuActive.value
})
const homeUrl = computed(() => {
    return props.model?.logoUrl && props.model?.logoUrl.url ? props.model?.logoUrl.url : '/'
})
const textColorClass = computed(() => {
    return isFrontPage.value && !shrinkHeader.value && !invertFrontPageHeader.value ? 'text-light' : 'text-dark'
})

const handleOverlayClick = () => {
    if (store.state.isMenuOpen) store.commit('toggleMenu', false)
}
</script>

<style src="./headerBlock.scss" lang="scss"></style>
