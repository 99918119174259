<!--
    Will select a view component based on the content type name.
    It gets its `model` from the vuex stores `cmsDataModel` module that's
    automatically updated in OPE when data is edited (through the `contentSaved` event).
    The store also includes `modelLoaded` that's set to true when the model has
    been populated in the store. This flag toggles the rendering of the page with `v-if`.
-->

<template>
    <template v-if="modelLoaded">
        <header-block v-if="config.headerReference" :model="config.headerReference" />

        <transition name="page" mode="out-in">
            <main id="main" :key="$route.fullPath">
                <component :is="getComponentForModel(model)" :model="model" />
            </main>
        </transition>

        <footer-block v-if="config.footerReference" :model="config.footerReference" />
    </template>
</template>

<script setup lang="ts">
import { resolveComponent } from 'vue'
import { ContentData } from '@/episerver/models'
// import { CmsRootState as RootState } from '@/store'
import { defaultCmsConfig } from '@/config'
import HeaderBlock from '@/views/blocks/HeaderBlock/headerBlock.vue'
import FooterBlock from '@/views/blocks/FooterBlock/footerBlock.vue'
import { mapGetters } from '@/store/helpers'

const { model, config, modelLoaded } = mapGetters()

const getComponentForModel = (model: ContentData): string | null => {
    if (!model.contentType) return null

    // Blocks are only loaded in edit mode, i.e.
    // we should display them in our preview component.
    if (model.contentType === 'Block') {
        return defaultCmsConfig.componentSelector.blockPreviewComponent
    }

    const resolved = resolveComponent(model.contentType)
    return typeof resolved === 'object' ? model.contentType : null
}
</script>
