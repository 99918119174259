<template>
    <div class="video">
        <template v-if="props.model.thumbnailImage?.url">
            <div class="video__preview" :class="{ 'video__preview--hide': videoPlaying }">
                <smart-image :url="props.model.thumbnailImage.url" :ratio="playerRatio" />
            </div>
            <button
                class="video__play-button"
                :class="{
                    'video__play-button--small-padding': !props.model.durationOverride,
                    'video__play-button--hide': videoPlaying && !videoEnded,
                }"
                type="button"
                @click="playVideo"
            >
                <span class="video__play-button--icon" />
                {{ props.model.durationOverride }}
            </button>
            <iframe
                :id="`player-${props.model.youtubeId}`"
                class="video__embedded"
                :width="playerWidth"
                :height="playerHeight"
                :src="videoSource"
                :style="playerRatioStyle"
                frameborder="0"
                allow="encrypted-media; fullscreen"
            />
        </template>
    </div>
</template>

<script setup lang="ts">
import { computed, onBeforeUnmount, onMounted, ref } from 'vue'
import { IYoutubeBlockModel } from '@/types'

const props = defineProps<{
    model: IYoutubeBlockModel
}>()

const videoPlaying = ref(false)
const videoEnded = ref(false)
const scrollPosition = ref(0)

let player: any

const playerWidth = computed(() => {
    return props.model.playerWidth ?? 1600
})

const playerHeight = computed(() => {
    return props.model.playerHeight ?? 900
})

const playerRatio = computed(() => {
    return `${playerWidth.value}/${playerHeight.value}`
})

const playerRatioStyle = computed(() => {
    return `aspect-ratio: ${playerRatio.value}`
})

const videoSource = computed(() => {
    return `https://www.youtube-nocookie.com/embed/${props.model.youtubeId}?rel=0&enablejsapi=1&mute=1&origin=${window.location.origin}`
})

const handleFullScreenChange = () => {
    if (!document.fullscreenElement) {
        window.scrollTo(0, scrollPosition.value)
    }
}

onMounted(() => {
    const tag = document.createElement('script')
    tag.src = 'https://www.youtube.com/iframe_api'
    const firstScriptTag = document.getElementsByTagName('script')[0]
    firstScriptTag.parentNode?.insertBefore(tag, firstScriptTag)

    if ((window as any).YT?.Player) {
        createPlayer()
    } else {
        // If the array doesn't exist yet, create it
        if (!(window as any).onYouTubeIframeAPIReadyCallbacks) {
            // eslint-disable-next-line no-extra-semi
            ;(window as any).onYouTubeIframeAPIReadyCallbacks = []
        }

        // Add createPlayer to the array of callbacks
        // eslint-disable-next-line no-extra-semi
        ;(window as any).onYouTubeIframeAPIReadyCallbacks.push(createPlayer)

        // Set onYouTubeIframeAPIReady to a function that calls all the callbacks
        ;(window as any).onYouTubeIframeAPIReady = () => {
            for (const callback of (window as any).onYouTubeIframeAPIReadyCallbacks) {
                callback()
            }
        }
    }

    const iframe = document.getElementById(`player-${props.model.youtubeId}`) as HTMLElement
    iframe.addEventListener('fullscreenchange', handleFullScreenChange)
})

onBeforeUnmount(() => {
    const iframe = document.getElementById(`player-${props.model.youtubeId}`) as HTMLElement
    iframe.removeEventListener('fullscreenchange', handleFullScreenChange)
})

const createPlayer = () => {
    player = new (window as any).YT.Player(`player-${props.model.youtubeId}`, {
        playerVars: {
            playsinline: 1,
        },
        events: {
            onStateChange: onStateChange,
        },
    })
}

const playVideo = () => {
    player.playVideo()
    videoPlaying.value = true
    scrollPosition.value = window.scrollY
}

const onStateChange = (event: any) => {
    if (event.data === (window as any).YT.PlayerState.ENDED) {
        videoEnded.value = true
    }

    if (event.data === (window as any).YT.PlayerState.PLAYING) {
        videoPlaying.value = true
        videoEnded.value = false
    }
}
</script>

<style lang="scss">
.video {
    position: relative;
    aspect-ratio: 16/9;

    &__preview {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        aspect-ratio: 16/9;
        z-index: 3;

        &--hide {
            z-index: 0;
        }
    }

    &__play-button {
        position: absolute !important;
        top: 50%;
        left: 50%;
        transform: translate3d(-50%, -50%, 0);
        background-color: $color-secondary;
        color: white;
        min-height: 80px;
        border-radius: 40px;
        display: inline-flex;
        flex-wrap: nowrap;
        justify-content: center;
        align-items: center;
        gap: 8px;
        overflow: hidden;
        border-width: 0;
        font-weight: 500;
        padding: 0 40px;
        cursor: pointer;
        z-index: 4;

        &--hide {
            z-index: 0;
        }

        &--small-padding {
            padding-left: 20px;
            padding-right: 20px;
        }

        &--icon {
            background: url(@/assets/svg/icon_system_Play.svg) center center no-repeat;
            width: 40px;
            height: 40px;
        }
    }

    &__embedded {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}
</style>
