import { createStore } from 'vuex'
import cmsDataModel from './modules/cmsDataModel'
import cmsContext from './modules/cmsContext'
// import search from './modules/search';
import { CmsContextState, CmsDataModelState } from './modules'

export type CmsRootState = {
    cmsContext: CmsContextState
    cmsDataModel: CmsDataModelState
    // search: SearchState,
    isMenuOpen: boolean
    windowWidth: number
    scrollPosition: number
    isTouchDevice: boolean
    allowTracking: boolean
}

const rootState = {
    isMenuOpen: false,
    windowWidth: 0,
    scrollPosition: 0,
    isTouchDevice: false,
    allowTracking: false,
} as CmsRootState

export default createStore<CmsRootState>({
    state: rootState,
    mutations: {
        toggleMenu(state, payload) {
            state.isMenuOpen = payload
        },
        setWindowWidth(state, payload) {
            state.windowWidth = payload
        },
        setScrollPosition(state, payload) {
            state.scrollPosition = payload
        },
        setDeviceType(state, payload) {
            state.isTouchDevice = payload
        },
        setAllowTracking(state, payload) {
            state.allowTracking = payload
        },
    },
    actions: {},
    modules: {
        cmsContext,
        cmsDataModel,
        // search
    },
})
