<!-- eslint-disable vue/html-self-closing -->
<template>
    <div
        v-if="props.model.widgetAPIKey"
        ref="widgetRef"
        class="teamtailor-jobs-widget"
        :data-teamtailor-limit="props.model.jobLimit"
        :data-teamtailor-api-key="props.model.widgetAPIKey"
    ></div>
</template>

<script setup lang="ts">
import { IJobWidgetBlockModel } from '@/types'
import { Ref, onMounted, onUnmounted, ref } from 'vue'

const props = defineProps<{
    model: IJobWidgetBlockModel
}>()

const widgetRef: Ref<HTMLElement | null> = ref(null)
let scriptTagWidget: HTMLScriptElement | null = null

const loadWidgetScript = () => {
    scriptTagWidget = document.createElement('script')
    scriptTagWidget.src = 'https://scripts.teamtailor-cdn.com/widgets/eu-pink/jobs.js'
    const firstScriptTag = document.getElementsByTagName('script')[0]
    firstScriptTag.parentNode?.insertBefore(scriptTagWidget, firstScriptTag)
}

const removeWidgetScript = () => {
    // eslint-disable-next-line no-extra-semi
    ;(window as any).TEAMTAILOR_JOB_SCRIPT_LOADED = false
    scriptTagWidget?.parentNode?.removeChild(scriptTagWidget)
    scriptTagWidget = null
}

onMounted(() => {
    loadWidgetScript()
    const observer = new MutationObserver((mutationsList) => {
        for (let mutation of mutationsList) {
            if (mutation.type === 'childList') {
                if (widgetRef.value) {
                    const anchors = widgetRef.value.querySelectorAll('.teamtailor-jobs__job-title')
                    anchors.forEach((anchor) => {
                        anchor.setAttribute('target', '_blank')
                        anchor.setAttribute('rel', 'noopener')
                    })
                }
            }
        }
    })

    if (widgetRef.value) {
        observer.observe(widgetRef.value, { attributes: false, childList: true, subtree: true })
    }
})

onUnmounted(() => {
    removeWidgetScript()
})
</script>

<style lang="scss">
.teamtailor-jobs {
    &__job {
        margin-bottom: 0 !important;
        border-bottom: 1px solid #d9d9d9b3;
        padding: 16px;

        @media (hover: hover) {
            &:hover {
                background-color: $color-grey-100-transparent;
            }
        }

        &:last-of-type {
            border: 0;
        }
    }

    &__job-title {
        text-decoration: none !important;
        font-size: $font-size-md;
        font-family: $font-family-amalia;
    }

    &__job-info {
        font-size: $font-size-xs;
    }
}
</style>
