<template>
    <smart-link
        ref="tileEl"
        class="tile d-flex"
        :url="props.model.link?.url"
        :title="props.model.tileTitle"
        :target="props.model.target"
        :is-external-link="props.model.link?.isExternalLink"
        :use-vue-route="props.model.link?.useVueRoute"
        gtm-action="tile"
        :gtm-label="props.model.tileText"
    >
        <div class="tile__inner text-center d-flex flex-col">
            <!-- <inline-svg
        :src="props.model.svgUrl.url"
        :aria-label="props.model.tileTitle"
        :height="props.model.svgUrl.properties.height"
        :width="props.model.svgUrl.properties.width"
      /> -->
            <smart-svg
                v-if="props.model.svgUrl && props.model.svgUrl.url"
                :url="props.model.svgUrl.url"
                :aria-label="props.model.tileTitle"
                :height="props.model.svgUrl?.properties?.height"
                :width="props.model.svgUrl?.properties?.width"
            />
            <span class="mt-2" :style="{ fontSize: `${fontSize}px` }" v-html="props.model.tileText" />
        </div>
    </smart-link>
</template>

<script setup lang="ts">
import { ref, computed, onMounted, onBeforeUnmount } from 'vue'
import { TileBlockModel } from '@/types'
import SmartSvg from '@/components/SmartSvg/SmartSvg.vue'

const props = defineProps<{
    model: TileBlockModel
}>()

let width = ref(0)
const tileEl = ref<HTMLElement>()

const fontSize = computed(() => {
    return width.value / 10 < 18 ? width.value / 10 : 18
})

const handleResize = () => {
    // @ts-expect-error
    width.value = tileEl.value?.$el.offsetWidth
}

onMounted(() => {
    window.addEventListener('resize', handleResize)
    handleResize()
})

onBeforeUnmount(() => {
    window.removeEventListener('resize', handleResize)
})
</script>

<style lang="scss">
.tile {
    position: relative;
    text-decoration: none;
    background-color: $color-grey-100;
    // aspect-ratio: 1 / 1;
    padding-bottom: 100%;
    box-shadow: 0 0 0 0 $color-grey-100;
    transition: background-color $transition-dur ease, box-shadow $transition-dur ease;
    will-change: background-color, box-shadow;

    &:hover {
        background-color: lighten($color-grey-100, 3%);
        box-shadow: 0 0 0 0.4em lighten($color-grey-100, 3%);
    }

    &:active {
        box-shadow: 0 0 0 0 lighten($color-grey-100, 3%);
    }

    &__inner {
        position: absolute;
        left: 50%;
        top: 50%;
        width: calc(100% - #{$spacer-lg});
        transform: translate(-50%, -50px);
        align-items: center;

        @media only screen and (min-width: 769px) and (max-width: 1023px) {
            transform: translate(-50%, calc(-50% + 5%));
        }

        svg {
            max-width: 56px;
            max-height: 56px;
            width: 40%;
            height: 40%;
        }
    }
}
</style>
