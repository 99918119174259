<template>
    <router-view />
</template>

<script setup lang="ts">
import { computed, onMounted, onBeforeUnmount, onBeforeMount, watch, ref } from 'vue'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
import { defaultConfig } from '@/episerver/config'

const store = useStore()
const route = useRoute()

const firstLoad = ref(true)

const meta = computed(() => {
    // eslint-disable-next-line
    const { metaDescription, metaKeywords, metaTitle, ogImage, ogTitle, ogDescription } = store.getters.model
    return {
        metaDescription,
        metaKeywords,
        metaTitle,
        ogImage,
        ogTitle,
        ogDescription,
    }
})

const canonicalUrl = computed(() => {
    // eslint-disable-next-line
    return store.getters.model.canonicalUrl
})

const handleResize = () => {
    store.commit('setWindowWidth', window.innerWidth)
}
const handleScroll = () => {
    store.commit('setScrollPosition', document.documentElement.scrollTop || document.body.scrollTop)
}
const handleTracking = () => {
    // remove data-gtm attributes on links if no statistical consent for cleaner markup
    // eslint-disable-next-line
    if ((window as any).CookieInformation === undefined) return
    const trackingAllowed = (window as any).CookieInformation.getConsentGivenFor('cookie_cat_statistic')
    store.commit('setAllowTracking', trackingAllowed)
}
const isTouchDevice = () => {
    const isTouchDevice =
        'ontouchstart' in window ||
        navigator.maxTouchPoints > 0 ||
        // eslint-disable-next-line
        (navigator as any).msMaxTouchPoints > 0
    store.commit('setDeviceType', isTouchDevice)
    return isTouchDevice
}
const getScrollbarWidth = () => {
    if (isTouchDevice()) return
    const scrollbarWidth = window.innerWidth - document.documentElement.clientWidth
    document.documentElement.style.setProperty('--scrollbarWidth', `${scrollbarWidth}px`)
}
const setMetaData = () => {
    document.title = meta.value.metaTitle ? meta.value.metaTitle : 'Alm. Brand Group'

    const metaData = [
        {
            type: 'description',
            value: meta.value.metaDescription || '',
            property: 'name',
        },
        {
            type: 'keywords',
            value: meta.value.metaKeywords || '',
            property: 'name',
        },
        {
            type: 'og:image',
            value: meta.value.ogImage && meta.value.ogImage.url ? meta.value.ogImage.url : '',
            property: 'property',
        },
        {
            type: 'og:description',
            value: meta.value.ogDescription || '',
            property: 'property',
        },
        {
            type: 'og:title',
            value: meta.value.ogTitle || '',
            property: 'property',
        },
    ]
    metaData.forEach((meta) => {
        const metaTag = document.querySelector(`meta[${meta.property}="${meta.type}"]`)

        if (!metaTag) {
            let tag = document.createElement('meta')
            tag.setAttribute(meta.property, meta.type)
            tag.setAttribute('content', meta.value)
            document.head.appendChild(tag)
        } else {
            metaTag.setAttribute('content', meta.value)
        }
    })
}
const setCanonicalUrl = () => {
    const tag = document.querySelector('link[rel="canonical"]')
    if (tag) tag.setAttribute('href', canonicalUrl.value.url)
}
const trackPageView = () => {
    const startMark = firstLoad.value ? 'firstLoadMark' : 'betweenRoutesMark'
    const performance = window.performance.measure('page_speed', startMark, 'end')

    // eslint-disable-next-line
    ;(window as any).dataLayer.push({
        event: 'pageView',
        page: canonicalUrl.value.path || window.location.pathname,
        title: document.title,
        location: canonicalUrl.value.url || window.location.href,
        page_speed: performance.duration,
    })
    if (!firstLoad.value) {
        // eslint-disable-next-line
        ;(window as any).dataLayer.push({
            event: 'gtm.load',
            'gtm.uniqueEventId': 0,
        })
    }
}

const appendEpiEditScripts = (domainUrl: string) => {
    const domainScript = document.createElement('script')
    domainScript.innerHTML = "document.domain = 'localhost';"
    document.body.appendChild(domainScript)

    const communicationScript = document.createElement('script')
    communicationScript.src = `${domainUrl}/episerver/cms/latest/clientresources/epi-cms/communicationinjector.js`
    document.body.appendChild(communicationScript)
}

onBeforeMount(() => {
    window.addEventListener('resize', handleResize)
    window.addEventListener('scroll', handleScroll)
    window.addEventListener('cookieInformationConsentGiven', handleTracking, {
        once: true,
    })
})

onMounted(() => {
    const element = document.getElementById('app')
    const domainUrl = element?.getAttribute('data-domain') ?? import.meta.env.VITE_APP_BASEURL ?? ''
    const contentDeliveryApiUri =
        element?.getAttribute('data-contentdelivery-url') ?? import.meta.env.VITE_APP_CONTENTDELIVERY_URL ?? ''
    const language = element?.getAttribute('data-language') ?? ''
    store.getters.model.language = language
    defaultConfig.apiUrl = domainUrl + contentDeliveryApiUri
    defaultConfig.selectAllProperties = true
    defaultConfig.expandAllProperties = true
    /*
     * If the `epieditmode` parameter is present we know we're
     * in either edit- or preview mode and should include following scripts.
     */
    if (route.query.epieditmode || document.location.search.includes('epieditmode')) {
        appendEpiEditScripts(domainUrl)
    }

    document.onreadystatechange = () => {
        // wait for DOM to render
        if (document.readyState === 'complete') setTimeout(getScrollbarWidth, 100)

        // value used to measure page speed
        firstLoad.value = false
    }

    handleResize()
    handleScroll()
    handleTracking()
})

onBeforeUnmount(() => {
    window.removeEventListener('resize', handleResize)
    window.removeEventListener('scroll', handleScroll)
})

watch(
    () => meta.value,
    () => {
        // on page change
        setMetaData()
        setCanonicalUrl()

        window.performance.mark('end')
        // eslint-disable-next-line
        if (store.state.allowTracking && (window as any).dataLayer) trackPageView()
    }
)
</script>
