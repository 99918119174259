<template>
    <div
        :key="$route.fullPath"
        v-inview
        :class="[
            'hero',
            'flex-col',
            {
                'hero--mobile': isMobile,
                'hero--frontpage flex-v-center grid grid--fluid': isFrontpageHero,
            },
        ]"
        @intersecting="setInView(true)"
    >
        <smart-image
            v-if="hasImg"
            :class="['hero__img', { 'hero__img--portrait': props.model.imageBW }]"
            :url="props.model.image?.url"
            :alt="props.model.image?.properties?.altText"
            :height="props.model.image?.properties?.height"
            :width="props.model.image?.properties?.width"
            :focal-point="props.model.image?.properties?.imageFocalPoint"
            type="bg"
        />

        <div :class="{ span12: isFrontpageHero }">
            <div :class="['hero__text flex-col grid', { 'grid--contained': isFrontpageHero }]">
                <div v-if="props.model.heading" class="span6 span8--md">
                    <h1
                        :class="[
                            'heading text-reveal d1',
                            marginTop,
                            { 'text-reveal--in': isInView, 'heading--small': props.model.smallHeading },
                        ]"
                        v-html="props.model.heading"
                    />
                </div>
                <div class="span5 span6--md">
                    <div
                        v-if="props.model.textArea"
                        :class="['text-highlight mb-2 text-area text-reveal d2', { 'text-reveal--in': isInView }]"
                        v-html="props.model.textArea"
                    />

                    <div v-if="hasCta" :class="['text-reveal d3', { 'text-reveal--in': isInView }]">
                        <smart-link
                            class="btn-solid-lg-primary mt-3 mb-2"
                            :url="props.model.cta.link.url"
                            :target="props.model.cta.target"
                        >
                            {{ props.model.cta.title }}
                        </smart-link>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { ref, onMounted, onUnmounted, computed } from 'vue'
import { useStore } from 'vuex'
import { isEmpty } from 'lodash-es'
import { HeroBlockModel } from '@/types'

const store = useStore()
const props = defineProps<{
    model: HeroBlockModel
}>()

let inView = ref(false)
let windowWidthAtInit = ref(0)

const handleResize = () => {
    if (windowWidthAtInit.value === window.innerWidth && store.state.isTouchDevice) {
        return
    }

    setHeroHeight()
}
const setHeroHeight = () => {
    // convert vh unit to pixels for mobile devices with address bar,
    // prevent vh-unit changing when address bar disappear
    let vh = window.innerHeight * 0.01
    document.documentElement.style.setProperty('--vh', `${vh}px`)
}
const handleOrientationChange = () => {
    windowWidthAtInit.value = window.innerWidth
    setHeroHeight()
}

onMounted(() => {
    setHeroHeight()
    windowWidthAtInit.value = window.innerWidth
    window.addEventListener('resize', handleResize)
    window.addEventListener('orientationchange', handleOrientationChange)
})

onUnmounted(() => {
    window.removeEventListener('resize', handleResize)
    window.removeEventListener('orientationchange', handleOrientationChange)
})

const isInView = computed(() => {
    return inView.value
})

const setInView = (val: boolean) => {
    inView.value = val
}

const hasCta = computed(() => {
    return props.model?.cta.link && props.model?.cta.link.url
})

const hasImg = computed(() => {
    return !isEmpty(props.model?.image)
})
const isMobile = computed(() => {
    return store.state.windowWidth < 768
})
const isFrontpageHero = computed(() => {
    return store.state.cmsDataModel.model.contentType.toLowerCase() === 'frontpage'
})

const marginTop = computed(() => {
    return props.model?.textArea ? 'mt-0' : 'mt-5'
})
</script>

<style lang="scss">
.hero {
    $mobileClass: '';
    $heroText: '';
    $heroImg: '';
    $heroFrontpage: '';
    $heroProfilePage: ''; // different gradient

    &--mobile {
        $mobileClass: &;
    }

    &--frontpage {
        $heroFrontpage: &;
        margin-top: calc(var(--headerHeight) * -1);
    }

    position: relative;
    overflow: hidden;

    &__text {
        $heroText: &;
        color: $color-white;
        z-index: z(element, default);
        padding-top: $spacer-xxl * 0.865;
        padding-bottom: $spacer-xxl * 0.85;

        #{$heroFrontpage} & {
            padding-top: calc(var(--headerHeight) + #{$spacer-xxl});
            padding-bottom: $spacer-xxl;
        }

        #{$mobileClass} & {
            background-color: $color-black;
            padding-top: $spacer-xl;
            padding-bottom: $spacer-xl;

            & > div > * {
                margin: 0 $spacer-lg $spacer-lg;
            }
        }
    }

    &__img {
        $heroImg: &;

        &.smart-img {
            position: absolute;
            z-index: -1;
        }

        #{$mobileClass} & {
            position: relative;
            // aspect-ratio: 4 / 3;
            padding-bottom: 75%;
        }

        &--portrait {
            $heroProfilePage: &;
        }
    }

    &:not(#{$mobileClass}) {
        // min-height: clamp(450px, calc(var(--vh, 1vh) * 60), 650px);
        min-height: unquote('min(max(450px, calc(var(--vh, 1vh) * 60)), 650px)');

        &#{$heroFrontpage} {
            &.hero {
                // min-height: clamp(500px, calc(var(--vh, 1vh) * 80), 700px);
                min-height: unquote('min(max(500px, calc(var(--vh, 1vh) * 80)), 700px)');
            }
        }
    }

    &:not(#{$heroFrontpage}):not(#{$mobileClass}) {
        #{$heroImg} {
            // no gradient on profile page heroes
            &::after {
                content: '';
                position: absolute;
                height: 100%;
                width: 100%;
                left: 0;
                top: 0;
                background: rgba($color-black, 0.4);
                background: linear-gradient(
                    90deg,
                    rgba($color-black, 0.5) 0%,
                    rgba($color-black, 0.25) 65%,
                    rgba($color-black, 0) 100%
                );
            }
        }

        #{$heroProfilePage} {
            &::after {
                background: linear-gradient(
                    90deg,
                    rgba($color-black, 0.25) 0%,
                    rgba($color-black, 0.15) 35%,
                    rgba($color-black, 0) 100%
                );
            }
        }

        #{$heroText} {
            & > div > * {
                margin-left: $spacer-xxl;
            }
        }
    }
}

.heading--small {
    font-size: 32px;

    @media only screen and (min-width: $breakpoint-sm) {
        font-size: 38px;
    }
    @media only screen and (min-width: $breakpoint-md) {
        font-size: 45px;
    }
}

.text-reveal {
    position: relative;
    transform: translate3d(0, 20px, 0);
    opacity: 0;
    transition: all $transition-dur-long ease-out;

    &--in {
        opacity: 1;
        transform: translate3d(0, 0, 0);
    }

    @for $i from 1 through 3 {
        &.d#{$i} {
            transition-delay: #{$i * 0.15}s;
        }
    }
}
</style>
