/*
 * The module that is responsible for handling the state of the current content
 * that is being either viewed or edited. This module will handle talking to
 * the API when the model needs to be updated when navigating or editing the
 * site.
 */

/* eslint-disable */
import { ContentResolver, ResolvedContent, ResolvedContentStatus } from '../../episerver/ContentResolver'
import { ContentData, PageData } from '../../episerver/models'
import { ActionTree, Module, MutationTree, GetterTree } from 'vuex'
import { defaultCmsConfig as config } from '../../config'
import { isEmpty } from 'lodash-es'

export type CmsDataModelState = {
    model: ContentData | Record<string, never>
    configuration: ContentData | Record<string, never>
    language: string
    modelLoaded: boolean
    status: ResolvedContentStatus
}

const UPDATE_MODEL_BY_URL = config.store.cmsDataModel.actions.UPDATE_MODEL_BY_URL

const UPDATE_MODEL = config.store.cmsDataModel.mutations.UPDATE_MODEL

const state: CmsDataModelState = {
    model: {},
    configuration: {},
    language: '',
    modelLoaded: false,
    status: ResolvedContentStatus.Unknown,
}

const mutations: MutationTree<CmsDataModelState> = {
    [UPDATE_MODEL](state: CmsDataModelState, payload: ResolvedContent<PageData>) {
        state.model = payload.content || {}

        if (!isEmpty(payload.content?.frontendConfiguration)) {
            state.configuration = payload.content?.frontendConfiguration || {}
        }

        if (payload.content?.language?.currentLanguage) {
            state.language = payload.content?.language.currentLanguage
        }

        state.modelLoaded = payload.status === ResolvedContentStatus.Resolved
        state.status = payload.status
    },
}

const actions: ActionTree<CmsDataModelState, unknown> = {
    async [UPDATE_MODEL_BY_URL]({ commit }, url: string) {
        const contentResolver = new ContentResolver()

        return contentResolver
            .resolveContent(url, true)
            .then((resolvedContent) => {
                commit(UPDATE_MODEL, { ...resolvedContent })

                const context = {
                    isEditable: resolvedContent.mode === 'EDIT',
                    inEditMode: resolvedContent.mode === 'EDIT',
                }

                commit(config.store.cmsContext.mutations.UPDATE_CONTEXT, context)
            })
            .catch(() =>
                commit<{ type: string; status: ResolvedContentStatus }>({
                    type: UPDATE_MODEL,
                    status: ResolvedContentStatus.Unknown,
                })
            )
    },
}

const getters: GetterTree<CmsDataModelState, unknown> = {
    language: (state: CmsDataModelState) => {
        return state.model.language
    },
    model: (state: CmsDataModelState) => {
        return state.model
    },
    config: (state: CmsDataModelState) => {
        return state.configuration
    },
    modelLoaded: (state: CmsDataModelState) => {
        return state.modelLoaded
    },
}

export default {
    state,
    mutations,
    actions,
    getters,
}
