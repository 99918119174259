<template>
    <div class="epi-preview">
        <div class="container">
            <div class="my-2 text-left">
                Preview display option:
                <select v-model="selectedSpan">
                    <option
                        v-for="(span, index) in sizes"
                        :key="`option-${index}`"
                        :value="span === 'automatic' ? span : `span${span}`"
                    >
                        {{ span }}<span v-if="span !== 'automatic'">/12</span>
                    </option>
                </select>
            </div>

            <div :class="getInnerGridType(props.model)">
                <div
                    :class="[
                        getGridSizeClasses(props.model.innerGrid, selectedSpan, props.model.content.contentType),
                        getMarginClass(props.model),
                        'epi-preview-span',
                    ]"
                    :data-epi-block-id="isEditable ? props.model.blockId : null"
                    :style="{ backgroundColor: getInnerBackgroundColor(model) }"
                >
                    <cms-block-component-selector :model="getModelFromBlock(props.model)" />
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { useStore } from 'vuex'
import { ref, computed } from 'vue'
import { GridModel } from '@/types'
import CmsBlockComponentSelector from '@/components/CmsBlockComponentSelector/CmsBlockComponentSelector.vue'
import {
    getInnerGridType,
    getMarginClass,
    getInnerBackgroundColor,
    getModelFromBlock,
} from '@/components/CmsContentArea/methods'

const store = useStore()
const props = defineProps<{
    model: any
}>()

const selectedSpan = ref('automatic')
const sizes = ['automatic', 12, 11, 10, 9, 8, 7, 6, 5, 4, 3, 2, 1]

const isEditable = computed(() => store.state.cmsContext.isEditable)

const getGridSizeClasses = (block: GridModel, size: string, contentType: string): string => {
    if (block === undefined) return ''

    let text = ''

    if (size !== 'automatic') {
        text += size
    } else {
        if (contentType === 'ParagraphBlock' || contentType === 'MediaBlock') {
            // defaults for certain blocks
            text += 'span8 move2 span10--md move1--md'
        } else {
            text += 'span12' // all others, no display setting applied
        }
    }

    if (block.gridMove && size !== '') {
        text += ` ${block.gridMove}`
    }

    if (block.tabletGridSize && block.tabletGridSize !== 'None' && size !== '') {
        text += ` ${block.tabletGridSize}--md`
    }

    if (block.tabletGridMove && size !== '') {
        text += ` ${block.tabletGridMove}--md`
    }

    if (block.mobileGridSize && block.mobileGridSize !== 'None') {
        text += ` ${block.mobileGridSize}--sm`
    }

    if (block.mobileGridMove) {
        text += ` ${block.mobileGridMove}--sm`
    }

    return text
}
</script>

<style lang="scss" scoped>
.epi-preview {
    margin-top: calc(var(--headerHeight) * -1);

    &-span {
        box-shadow: 0 0 0 2px darkseagreen;
    }
}
</style>
