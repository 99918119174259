import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import CmsPageComponentSelector from '@/components/CmsPageComponentSelector/CmsPageComponentSelector.vue'
import DocsPage from '@/views/pages/Docs.vue'
import { defaultCmsConfig } from '@/config'
import store from '@/store'
import { PageData } from '@/episerver/models'

const routes: Array<RouteRecordRaw> = [
    {
        name: 'page-component-selector',
        path: '/:pathMatch(.*)',
        component: CmsPageComponentSelector,
    },
]

if (import.meta.env.DEV) {
    routes.push({
        name: 'docs',
        path: '/docs',
        component: DocsPage,
    })
}

const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior(to, from, savedPosition) {
        // return to scrolled position using browser history back
        return new Promise((resolve) => {
            setTimeout(() => {
                let position = { left: 0, top: 0 }
                if (savedPosition) position = savedPosition
                resolve(position)
            }, 320) // match page transition duration + small render delay for consistent positioning
        })
    },
})

router.beforeEach((to, from, next) => {
    // URL is updated by vue-route-sync, and when time travelling with the
    // debugger we don't want to trigger a model commit as the model is already
    // part of the store holding the url update.
    if (to.name === 'page-component-selector' && (store.state.cmsDataModel.model as PageData).url !== to.fullPath) {
        store.dispatch(defaultCmsConfig.store.cmsDataModel.actions.UPDATE_MODEL_BY_URL, to.fullPath).then(() => {
            switch (store.state.cmsDataModel.status) {
                case 'NOTFOUND':
                case 'UNAUTHORIZED':
                case 'ACCESSDENIED':
                default:
            }
        })
    }

    // set timing marker for page speed tracking between routes
    // eslint-disable-next-line
    if (store.state.allowTracking && (window as any).dataLayer) window.performance.mark('betweenRoutesMark')

    next()
})

router.afterEach(() => {
    if (store.state.isMenuOpen) store.commit('toggleMenu', false)
})

export default router
