<template>
    <component :is="getComponentForModel(props.model)" :model="props.model" />
</template>

<script setup lang="ts">
import { resolveComponent } from 'vue'
import { ContentData } from '@/episerver/models'
import { defaultCmsConfig } from '@/config'

const props = defineProps<{
    model: ContentData
}>()

const getComponentForModel = (model: ContentData): string => {
    const resolved = resolveComponent(model.contentType)
    return typeof resolved === 'object' ? model.contentType : defaultCmsConfig.componentSelector.fallbackBlockComponent
}
</script>
