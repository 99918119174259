<template>
    <component
        :is="tagType"
        v-if="url"
        ref="linkEl"
        :to="url"
        :href="url"
        :class="defaultLinkCssClass"
        :target="target"
        :title="title"
        :data-gtm-label="defaultGtmLabel"
        :data-gtm-action="defaultGtmAction"
        :rel="isExternalLink ? 'noopener' : null"
        @click="$event.target.blur()"
    >
        <slot />
    </component>
</template>

<script setup lang="ts">
import { ref, onMounted, computed, onUpdated } from 'vue'
import { useStore } from 'vuex'
import { defaultCmsConfig } from '@/config'

/* eslint-disable vue/require-default-prop, no-undef */
interface Props {
    url: string
    defaultLinkCssClass?: any
    target?: string
    title?: string
    gtmLabel?: string
    gtmAction?: string
    isExternalLink?: boolean
    useVueRoute?: boolean
}

const store = useStore()
const props = withDefaults(defineProps<Props>(), {
    defaultLinkCssClass: () => defaultCmsConfig.link.cssClass,
    isExternalLink: false,
    useVueRoute: true,
    target: '_self',
})

const linkEl = ref<HTMLElement>()
const linkText = ref('')

const trackingAllowed = computed(() => {
    return store.state.allowTracking
})

const tagType = computed(() => {
    // summary:
    //      Define whether we should use the tag 'a' or 'router-link' when generating a link.
    //      The reason is because <router-link> doesn't support absolute link
    //      (https://github.com/vuejs/vue-router/issues/1131), which happens when we link to a page
    //      in another site in a multi-sites system.
    //      There is an open feature-request for making 'router-link' support absolute links.
    //      https://github.com/vuejs/vue-router/issues/1280
    //
    //      Always user an 'a' tag in edit mode to update the Episerver UI
    if (store.state.cmsContext.inEditMode) {
        return 'a'
    }
    return props.useVueRoute ? 'router-link' : 'a'
})

const defaultGtmLabel = computed(() => {
    if (!trackingAllowed.value) return null
    // use linktext for gtm tracking if none provided
    return props.gtmLabel ? props.gtmLabel : linkText.value
})

const defaultGtmAction = computed(() => {
    if (!trackingAllowed.value) return null
    return props.gtmAction ? props.gtmAction : 'button'
})

const getLinkText = () => {
    // @ts-expect-error
    const element = linkEl.value?.$el ? linkEl.value?.$el : linkEl.value
    linkText.value = (element as HTMLElement).innerText
}

onMounted(() => {
    getLinkText()
})

onUpdated(() => {
    getLinkText()
})
</script>
