<template>
    <div v-if="isMultiLingual" :class="['lang-picker', props.textColor]">
        <template v-for="(lang, index) in langData" :key="index">
            <smart-link
                :url="lang.url"
                :class="['lang-picker__language', { 'lang-picker__language--active': lang.isCurrentLanguage }]"
                :gtm-action="props.gtmActionCat ? props.gtmActionCat : null"
            >
                {{ lang.languageCode }}
            </smart-link>
            <span v-if="index < langData.length - 1" class="lang-picker__divider" />
        </template>
    </div>
</template>

<script setup lang="ts">
import { computed, watch } from 'vue'
import { useStore } from 'vuex'
import { ContentLanguage } from '@/episerver/models'

const store = useStore()
const props = defineProps<{
    textColor?: string
    gtmActionCat?: string
}>()

const currentLanguage = computed((): string => {
    return store.getters.language.currentLanguage
})

const langData = computed(() => {
    return store.getters.language.languages.map((lang: ContentLanguage) => {
        return {
            ...lang,
            isCurrentLanguage: currentLanguage.value === lang.languageName ? true : false,
        }
    })
})

const isMultiLingual = computed(() => {
    return store.getters.language.languages.length > 1
})

watch(
    () => currentLanguage.value,
    (newLang) => {
        document.documentElement.lang = newLang
    }
)
</script>

<style lang="scss">
.lang-picker {
    width: max-content;
    font-weight: $font-weight-semibold;

    &__language {
        text-decoration: none;
        text-transform: capitalize;
        position: relative;
        padding: 0 $spacer-sm;
        transition: color $transition-dur-short $transition-ease-in-quad;

        .lang-picker--mobile &:not(.lang-picker__language--active) {
            color: $color-black !important;
        }

        &--active {
            color: $color-primary !important;
        }

        &:hover:not(.router-link-exact-active) {
            color: darken($color-primary, 5%);
        }
    }

    &__divider {
        display: inline-block;
        width: 2px;
        height: $font-size-sm;
        background-color: currentColor;
        vertical-align: text-bottom;
        transition: color $transition-dur-short $transition-ease-in-quad;

        .lang-picker--mobile & {
            color: $color-black;
        }
    }

    &--mobile {
        width: 100%;
        padding: $spacer-lg 0 $spacer-md $spacer-md;
        opacity: 0;
        will-change: opacity;

        .active & {
            transition: opacity $transition-dur-long $transition-ease-in-quad $transition-dur;
            opacity: 1;
        }
    }
}
</style>
