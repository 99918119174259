<template>
    <div class="container">
        <div
            v-for="(blockList, index) in props.model"
            :key="index"
            :class="getOuterGridType(blockList)"
            :style="{ backgroundColor: getOuterBackgroundColor(blockList) }"
        >
            <template v-if="useOuterGrid(blockList) === false">
                <div
                    v-for="(block, index2) in blockList.items"
                    :key="index2"
                    :class="[getGridSizeClasses(block.innerGrid, block.content?.contentType), getMarginClass(block)]"
                    :data-epi-content-id="isEditable ? (block as any).contentLink.guidValue : null"
                    :style="{ backgroundColor: getInnerBackgroundColor(block) }"
                >
                    <cms-block-component-selector :model="getModelFromBlock(block)" />
                </div>
            </template>

            <div v-else :class="getGridSizeClasses(blockList.outerGrid)">
                <div :class="getInnerGridType(blockList)">
                    <div
                        v-for="(block, index3) in blockList.items"
                        :key="index3"
                        :class="[getGridSizeClasses(block.innerGrid), getMarginClass(block)]"
                        :data-epi-content-id="
                            isEditable ? (block as any).contentLink.guidValue : null
                        "
                        :style="{
                            backgroundColor: getInnerBackgroundColor(block),
                        }"
                    >
                        <cms-block-component-selector :model="getModelFromBlock(block)" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { useStore } from 'vuex'
import { computed } from 'vue'
import { ContentAreaGroupApiModel } from '@/types'
import {
    getInnerGridType,
    getOuterGridType,
    getMarginClass,
    useOuterGrid,
    getInnerBackgroundColor,
    getOuterBackgroundColor,
    getGridSizeClasses,
    getModelFromBlock,
} from './methods'
import CmsBlockComponentSelector from '../CmsBlockComponentSelector/CmsBlockComponentSelector.vue'

type ContentAreaGroupApiModelArray = ContentAreaGroupApiModel[]

const store = useStore()
const props = defineProps<{
    model: ContentAreaGroupApiModelArray
}>()

const isEditable = computed(() => store.state.cmsContext.isEditable)
</script>
