<template>
    <div class="profile-card d-flex flex-col">
        <smart-image
            v-if="hasImg"
            class="profile-card__img"
            :url="props.model.image?.url"
            :alt="props.model.image?.properties?.altText"
            :height="props.model.image?.properties?.height"
            :width="props.model.image?.properties?.width"
            :focal-point="props.model.image?.properties?.imageFocalPoint"
            :ratio="imgRatio"
        />
        <h4 v-if="props.model.name" class="profile-card__name pt-3 mb-1" v-text="props.model.name" />
        <div v-if="props.model.jobTitle" class="profile-card__title" v-text="props.model.jobTitle" />
        <table class="profile-card__info mt-3 pb-3">
            <tr v-for="(label, index) in labels" :key="'label' + index">
                <template v-if="(props.model as any)[label.name]">
                    <td class="profile-card__info__label pl-0 pt-0 pb-1 pr-3" v-html="(label as any)[currentLang]" />
                    <td class="profile-card__info__value px-0 pt-0 pb-1" v-html="profileData[label.name]" />
                </template>
            </tr>
        </table>
    </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { useStore } from 'vuex'
import { isEmpty } from 'lodash-es'
import { ContactCardBlockModel } from '@/types'

interface labelInterface {
    name: string
    da: string
    en: string
}
type labelsType = labelInterface[]

const store = useStore()
const props = defineProps<{
    model: ContactCardBlockModel
}>()

const labels = <labelsType>[
    { name: 'mobile', da: 'Mobil', en: 'Mobile' },
    { name: 'phone', da: 'Telefon', en: 'Phone' },
    { name: 'email', da: 'Mail', en: 'Mail' },
    { name: 'birthDate', da: 'Født', en: 'D.o.b.' },
    { name: 'employmentDate', da: 'Ansat', en: 'Appointed' },
    { name: 'education', da: 'Udd.', en: 'Edu.' },
]

const hasImg = computed((): boolean => {
    return !isEmpty(props.model?.image)
})

const imgRatio = computed((): string => {
    return store.state.windowWidth > 768 ? '3/4' : '3/2'
})

const profileData = computed((): any => {
    let { mobile, phone, email, birthDate, employmentDate, education } = props.model

    birthDate = formatDate(birthDate)
    employmentDate = formatDate(employmentDate)

    store.state.allowTracking
        ? (email = `<a href="mailto:${email}" data-gtm-label="${email}" data-gtm-action="contact card">${email}</a>`)
        : (email = `<a href="mailto:${email}">${email}</a>`)

    if (isTouchDevice.value) {
        mobile = `<a href="tel:${mobile}">${mobile}</a>`

        store.state.allowTracking
            ? (phone = `<a href="tel:${phone}" data-gtm-label="${phone}" data-gtm-action="contact card">${phone}</a>`)
            : (phone = `<a href="tel:${phone}">${phone}</a>`)
    }

    return { mobile, phone, email, birthDate, employmentDate, education }
})

const currentLang = computed((): string => {
    return store.state.cmsDataModel.language.substring(0, 2)
})

const langCode = computed((): string => {
    return store.state.cmsDataModel.model.language.currentLanguage
})

const isTouchDevice = computed((): boolean => {
    return store.state.isTouchDevice
})

const formatDate = (datetime: string | undefined): string | undefined => {
    if (!datetime) return
    return new Date(datetime).toLocaleDateString(langCode.value, {
        day: 'numeric',
        month: 'short',
        year: 'numeric',
    })
}
</script>

<style lang="scss">
.profile-card {
    font-size: $font-size-xxs;
    height: 100%;

    &__name {
        border-top: 4px solid $color-black;
    }

    &__title {
        flex: 1;
    }

    &__info {
        border-spacing: 0;
        border-bottom: 2px solid $color-black;
        width: 100%;

        &__label {
            font-weight: $font-weight-semibold;
            vertical-align: top;
            width: 20%;
        }

        &__value {
            word-break: break-word;
        }
    }
}
</style>
