<template>
    <smart-image
        v-if="hasImg"
        :url="props.model.image!.url"
        :alt="props.model.image!.properties?.altText"
        :height="props.model.image!.properties!.height"
        :width="props.model.image!.properties!.width"
        :focal-point="props.model.image!.properties?.imageFocalPoint"
        :ratio="ratio"
    />
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { isEmpty } from 'lodash-es'
import { MediaBlockModel } from '@/types'

const props = defineProps<{
    model: MediaBlockModel
}>()

const hasImg = computed(() => {
    return !isEmpty(props.model?.image)
})

const ratio = computed(() => {
    return props.model && props.model.ratio !== 'default' ? props.model.ratio : null
})
</script>
