/*
 * The module responsible to handling Episerver specific state that is relevant
 * when editing content in edit mode.
 */

import { MutationTree } from 'vuex'
import { defaultCmsConfig } from '@/config'

// Mutation for the epiContext module
const UPDATE_CONTEXT = defaultCmsConfig.store.cmsContext.mutations.UPDATE_CONTEXT

export type CmsContextState = {
    inEditMode: boolean
    isEditable: boolean
}

const state: CmsContextState = {
    inEditMode: false,
    isEditable: false,
}

const mutations: MutationTree<CmsContextState> = {
    [UPDATE_CONTEXT](state: CmsContextState, newContext: CmsContextState): void {
        state.isEditable = newContext.isEditable
        state.inEditMode = newContext.inEditMode
    },
}

export default {
    state,
    mutations,
}
