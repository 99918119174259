<template>
    <h1 v-if="props.model.header" v-html="props.model.header" />
    <div v-if="props.model.contentText" class="text-large" v-html="props.model.contentText" />

    <div v-if="props.model.richText" v-html="props.model.richText" />

    <div v-if="props.model.buttons && props.model.buttons.length" :class="[marginTop, marginBottom]">
        <smart-link
            v-for="(button, index) in props.model.buttons"
            :key="index"
            :class="[getCtaStyle(button.style), 'mb-2']"
            :url="button.link.url"
            :title="button.title"
            :target="button.target"
            :use-vue-route="button.link.useVueRoute"
            :gtm-action="button.gtmAction"
            :gtm-label="button.gtmLabel"
            v-text="button.title"
        />
    </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { ParagraphBlockModel } from '@/types'

const props = defineProps<{
    model: ParagraphBlockModel
}>()

const marginTop = computed(() => {
    return props.model?.ctaMarginTop === 'default' ? 'mt-3' : `mt-${props.model?.ctaMarginTop}`
})

const marginBottom = computed(() => {
    return props.model?.ctaMarginBottom === 'default' ? 'mb-3' : `mb-${props.model?.ctaMarginBottom}`
})

const getCtaStyle = (btnClass: string) => {
    return btnClass === 'default' ? 'btn-solid-md-primary' : btnClass
}
</script>
